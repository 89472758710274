import {
  unstable_defineLoader as defineLoader,
  unstable_data as data,
} from '@remix-run/node'
import clsx from 'clsx'
import { useContext } from 'react'
import { useLocales } from 'remix-utils/locales/react'

import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { Link, useLoaderData, useRouteLoaderData } from '@remix-run/react'

import { UseBuyOnLoungePair } from '~/components/Mark'
import { getLoungesDirectoryCached } from '~/models/lounges.server'

import { SearchPaletteContext } from '~/contexts/SearchContext'
import type { loader as RootLoader } from '~/root'
import { getServerTiming, headersWithTiming } from '~/timing.server'
import { httpsInProduction } from '~/utils/utils.server'

export const headers = headersWithTiming(({ loaderHeaders }) => ({
  Link: loaderHeaders.get('Link')!,
  'Cache-Control':
    'public, max-age=300, s-maxage=3600, stale-while-revalidate=86400, stale-if-error=86400',
}))

export const handle = {
  features: { footer: false },
  getSitemapEntries: () => {
    return {
      route: '/directory/',
      priority: 0.7,
    }
  },
}

interface MinimalAirport {
  iata: string
  name: string
  lounges: {
    slug: string
    name: string
    location: string | null
    hasLoungePair: boolean
    lowestOffer?: {
      price: number | null
      currency: string
    }
  }[]
  isFirstLetter: boolean
}

export const loader = defineLoader(async (args) => {
  const { time, getServerTimingHeader } = getServerTiming()

  const airports = await time(
    'getLoungesDirectoryCached_index',
    getLoungesDirectoryCached(args).then((r) =>
      r.airports.map(
        (a) =>
          ({
            iata: a.iata,
            isFirstLetter: a.isFirstLetter,
            name: a.name,
            lounges: a.lounges.map((l) => ({
              slug: l.slug,
              name: l.name,
              location: l.location,
              hasLoungePair: l.hasLoungePair,
              lowestOffer: l.lowestOffer
                ? {
                    price: l.lowestOffer.price,
                    currency: l.lowestOffer.currency,
                  }
                : undefined,
            })),
          }) satisfies MinimalAirport,
      ),
    ),
  )

  const canonical = httpsInProduction(new URL(args.request.url))
  canonical.pathname = '/directory/'
  canonical.search = ''

  return data(
    { airports },
    {
      headers: getServerTimingHeader({
        Link: `<${canonical}>; rel="canonical"`,
      }),
    },
  )
})

export default function Home() {
  const { setIsOpen } = useContext(SearchPaletteContext)
  const { airports } = useLoaderData<typeof loader>()
  const root = useRouteLoaderData<typeof RootLoader>('root')

  return (
    <div>
      <nav aria-label="Directory" className="pb-20 directory">
        {airports.map((airport) => (
          <AirportWithLounges
            key={airport.iata}
            airport={airport}
            canToggleAffiliateOffers={root?.canToggleAffiliateOffers === true}
            flagsHideAffiliateOffers={root?.flagsHideAffiliateOffers === true}
          />
        ))}
      </nav>

      <div className="fixed bottom-0 z-10 w-full px-3 pb-2 prose prose-stone bg-white border-t border-stone-300">
        <div className="flex-1 min-w-0 md:px-8 lg:px-0 xl:col-span-6">
          <div className="flex items-center px-6 py-4 md:mx-auto md:max-w-3xl lg:mx-0 lg:max-w-none xl:px-0">
            <div className="w-full">
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <button
                type="button"
                className="relative w-full"
                onClick={() => setIsOpen(true)}
              >
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <MagnifyingGlassIcon
                    className="w-5 h-5 text-stone-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="block w-full py-2 pl-10 pr-3 font-mono text-left no-underline bg-white border rounded-md placeholder-stone-500 border-stone-300 focus:border-blue-500 focus:text-stone-900 focus:placeholder-stone-400 focus:outline-none focus:ring-1 focus:ring-blue-500">
                  Search for a lounge
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function AirportWithLounges({
  airport,
  canToggleAffiliateOffers,
  flagsHideAffiliateOffers,
}: React.PropsWithoutRef<{
  airport: MinimalAirport
  canToggleAffiliateOffers: boolean
  flagsHideAffiliateOffers: boolean
}>) {
  const locales = useLocales()
  return (
    <div className="relative not-prose">
      {airport.iata ? (
        <Link
          prefetch="intent"
          to={`/at/${airport.iata}/`}
          state={{ from: 'routes/directory/_index/_directory' }}
          className="link"
        >
          <h3 className={clsx(airport.isFirstLetter ? 'first-letter' : '')}>
            {airport.name}
            <span className="iata">({airport.iata})</span>
          </h3>
        </Link>
      ) : null}
      <ul className="lounges">
        {airport.lounges?.map((lounge) => (
          <li key={lounge.slug}>
            <Link
              prefetch="intent"
              to={`/at/${airport.iata}/${encodeURIComponent(lounge.slug)}/`}
              state={{ from: 'routes/directory/_index/_directory' }}
              className="focus:outline-none"
            >
              {!flagsHideAffiliateOffers &&
              canToggleAffiliateOffers &&
              lounge.hasLoungePair ? (
                <UseBuyOnLoungePair />
              ) : null}
              <span className="name">{lounge.name}</span>

              {lounge.lowestOffer?.currency && (
                <span
                  className={clsx(lounge.hasLoungePair ? 'offer lp' : 'offer')}
                >
                  {Intl.NumberFormat(locales, {
                    currency: lounge.lowestOffer.currency,
                    style: 'currency',
                  }).format(lounge.lowestOffer?.price ?? 0)}
                </span>
              )}

              {lounge.location && (
                <span className="location">{lounge.location}</span>
              )}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}
